import axios from "axios";
import l from "lodash";

const apiHost1 = "https://core-01.audi.es/";
const apiHost2 = "https://pre-core-01.audi.es/";
const apiHost3 = "https://promociones.audi.es/";
const apiHost4 = "https://cdn.audi.es/core/";
const apiHostLocal = "http://localhost:3001/";

const apiEndpoint = `${apiHost4}api/`;

const apiCarlines = `${apiEndpoint}getCarlines`;
const apiCarline = `${apiEndpoint}getCarline/<codigo>`;
const apiEscalera = `${apiEndpoint}getEscalera/<codigo>`;
const apiOfertas = `${apiEndpoint}getOfertas`;

class API {
	static getCarlines = (criteria) => {
		console.log("API/getCarlines");
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(apiCarlines, {
					timeout: 20000,
					params: {},
				});
				console.log("API/getCarlines Response OK", response);
				resolve(this.filterCarlines(response.data.carlines, criteria));
				// resolve(response.data.carlines);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/getCarlines Response ERROR",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getCarlines Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getCarlines Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getCarline = (criteria) => {
		console.log("API/getCarline", criteria);
		const { codigo } = criteria;
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(
					apiCarline.replace("<codigo>", codigo),
					{
						timeout: 20000,
						params: {},
					},
				);
				console.log("API/getCarline Response OK", response);
				resolve(this.filterCarlines([response.data.carline], criteria)[0]);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/getCarline Response ERROR",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getCarline Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getCarline Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getEscalera = (codigo) => {
		console.log("API/getEscalera", codigo);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(
					apiEscalera.replace("<codigo>", codigo),
					{
						timeout: 20000,
						params: {},
					},
				);
				console.log("API/getEscalera Response OK", response);
				resolve(response.data.motorizaciones);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/getEscalera Response ERROR",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getEscalera Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getEscalera Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getOfertas = (isEmpresa = false) => {
		console.log("API/getOfertas", isEmpresa);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(
					`${apiOfertas}?${isEmpresa ? "empresa=1" : ""}`,
					{
						timeout: 20000,
						params: {},
					},
				);
				console.log("API/getOfertas Response OK", response);
				resolve(response.data.ofertas);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/getOfertas Response ERROR",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getOfertas Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getOfertas Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	// utilidades

	static findMejorOfertaFromCarline = (carline, ofertaTipo = null) => {
		let ofertaElegida = null;
		let menorPrecio = null;
		if (carline.salesgroups) {
			carline.salesgroups.forEach((salesgroup) => {
				if (salesgroup.trimlevels) {
					salesgroup.trimlevels.forEach((trimlevel) => {
						if (
							trimlevel.ofertas &&
							trimlevel.ofertas !== null &&
							trimlevel.ofertas.length > 0
						) {
							trimlevel.ofertas.forEach((oferta) => {
								if (
									!oferta.isBanner &&
									(ofertaTipo === null || oferta.tipo === ofertaTipo)
								) {
									if (
										menorPrecio === null ||
										oferta.precio < menorPrecio
									) {
										menorPrecio = oferta.precio;
										ofertaElegida = oferta;
									}
								}
							});
						}
					});
				}
			});
		}
		return ofertaElegida;
	};

	static findMejorOfertaFromSalesgroup = (salesgroup) => {
		let ofertaElegida = null;
		let menorPrecio = null;
		if (salesgroup.trimlevels) {
			salesgroup.trimlevels.forEach((trimlevel) => {
				if (
					trimlevel.ofertas &&
					trimlevel.ofertas !== null &&
					trimlevel.ofertas.length > 0
				) {
					trimlevel.ofertas.forEach((oferta) => {
						if (!oferta.isBanner) {
							if (menorPrecio === null || oferta.precio < menorPrecio) {
								menorPrecio = oferta.precio;
								ofertaElegida = oferta;
							}
						}
					});
				}
			});
		}
		return ofertaElegida;
	};

	static findMejorOfertaFromSalesgroupByTipo = (tipo, salesgroup) => {
		let ofertaElegida = null;
		let menorPrecio = null;
		if (salesgroup.trimlevels) {
			salesgroup.trimlevels.forEach((trimlevel) => {
				if (
					trimlevel.ofertas &&
					trimlevel.ofertas !== null &&
					trimlevel.ofertas.length > 0
				) {
					trimlevel.ofertas.forEach((oferta) => {
						if (
							!oferta.isBanner &&
							oferta.tipo === tipo &&
							oferta.precio
						) {
							if (menorPrecio === null || oferta.precio < menorPrecio) {
								menorPrecio = oferta.precio;
								ofertaElegida = oferta;
							}
						}
					});
				}
			});
		}
		return ofertaElegida;
	};

	static findMejorOfertaFromTrimlevel = (trimlevel, avoidTipo) => {
		let ofertaElegida = null;
		let menorPrecio = null;
		if (
			trimlevel.ofertas &&
			trimlevel.ofertas !== null &&
			trimlevel.ofertas.length > 0
		) {
			trimlevel.ofertas.forEach((oferta) => {
				if (
					!oferta.isBanner &&
					oferta.precio &&
					oferta.tipo !== avoidTipo
				) {
					if (menorPrecio === null || oferta.precio < menorPrecio) {
						menorPrecio = oferta.precio;
						ofertaElegida = oferta;
					}
				}
			});
		}
		return ofertaElegida;
	};

	static findMejorOfertaFromEscalera = (tipo, escalera) => {
		let ofertaElegida = null;
		let menorPrecio = null;
		escalera.forEach((itemEscalera) => {
			itemEscalera.ofertas.forEach((oferta) => {
				if (!oferta.isBanner && oferta.tipo === tipo && !oferta.isEmpresa) {
					if (menorPrecio === null || oferta.precio < menorPrecio) {
						menorPrecio = oferta.precio;
						ofertaElegida = oferta;
					}
				}
			});
		});
		return ofertaElegida;
	};

	static findCarlineFromCarlines = (carlines, codigo) => {
		let c = null;
		carlines.forEach((carline) => {
			if (carline.codigo === codigo) {
				c = carline;
			}
		});
		return c;
	};

	static findSalesgroupFromCarline = (carline, codigo) => {
		let salesgroup = null;
		if (carline.salesgroups) {
			carline.salesgroups.forEach((s) => {
				if (s.codigo === codigo) {
					salesgroup = s;
				}
			});
		}
		return salesgroup;
	};

	static findOfertasByTipo = (salesgroup, tipo) => {
		console.log("API/findOfertasByTipo", salesgroup, tipo);
		const ofertas = [];
		salesgroup.trimlevels.forEach((trimlevel) => {
			if (
				trimlevel.ofertas &&
				trimlevel.ofertas !== null &&
				trimlevel.ofertas.length > 0
			) {
				trimlevel.ofertas.forEach((oferta) => {
					if (!oferta.isBanner && oferta.tipo === tipo)
						ofertas.push({
							trimlevel: trimlevel,
							oferta: oferta,
						});
				});
			}
		});
		return ofertas;
	};

	static filterCarlines = (carlines, criteria) => {
		const { isEmpresa, ofertaTipo } = criteria;
		// carlines
		const newCarlines = [];
		carlines.forEach((carline) => {
			if (carline.activoOfertas) {
				const newCarline = l.cloneDeep(carline);
				// salesgroups del carline
				newCarline.salesgroups = [];
				carline.salesgroups?.forEach((salesgroup) => {
					if (salesgroup.activoOfertas) {
						const newSalesgroup = l.cloneDeep(salesgroup);
						// ofertas del salesgroup
						newSalesgroup.ofertas = [];
						salesgroup.ofertas?.forEach((oferta) => {
							let errors = 0;
							if (
								!l.isUndefined(isEmpresa) &&
								oferta.isEmpresa !== isEmpresa
							)
								errors++;
							if (
								!l.isUndefined(ofertaTipo) &&
								oferta.tipo !== ofertaTipo
							)
								errors++;
							if (errors === 0) newSalesgroup.ofertas.push(oferta);
						});
						// trimlevels del salesgroup
						newSalesgroup.trimlevels = [];
						salesgroup.trimlevels?.forEach((trimlevel) => {
							if (trimlevel.activoOfertas) {
								const newTrimlevel = l.cloneDeep(trimlevel);
								// ofertas del trimlevel
								newTrimlevel.ofertas = [];
								trimlevel.ofertas?.forEach((oferta) => {
									let errors = 0;
									if (
										!l.isUndefined(isEmpresa) &&
										oferta.isEmpresa !== isEmpresa
									)
										errors++;
									if (
										!l.isUndefined(ofertaTipo) &&
										oferta.tipo !== ofertaTipo
									)
										errors++;
									if (errors === 0) newTrimlevel.ofertas.push(oferta);
								});
								if (newTrimlevel.ofertas.length) {
									newSalesgroup.trimlevels.push(newTrimlevel);
								}
							}
						});
						if (newSalesgroup.trimlevels.length) {
							newCarline.salesgroups.push(newSalesgroup);
						}
					}
				});
				if (newCarline.salesgroups.length) {
					newCarlines.push(newCarline);
				}
			}
		});
		return newCarlines;
	};

	static findBotonById(botones, id) {
		let boton = null;
		botones.forEach((b) => {
			if (b.id === id) boton = b;
		});
		return boton;
	}

	static buildFeaturesFromOferta = (oferta) => {
		const features = [];

		if (oferta.equipamientos?.serie) {
			const series = {
				title: "Equipamiento de serie",
				infoList: [],
			};
			oferta.equipamientos.serie.forEach((serie) => {
				series.infoList.push({ value: serie.titulo });
			});
			features.push(series);
		}

		if (oferta.equipamientos?.linea) {
			const lineas = {
				title: "Equipamiento de línea",
				infoList: [],
			};
			oferta.equipamientos.linea.forEach((linea) => {
				lineas.infoList.push({ value: linea.titulo });
			});
			features.push(lineas);
		}

		if (oferta.servicios) {
			const servicios = {
				title: "Servicios del producto financiero",
				infoList: [],
			};
			oferta.servicios.forEach((servicio) => {
				servicios.infoList.push({ value: servicio.titulo });
			});
			features.push(servicios);
		}

		if (oferta.condiciones) {
			const condiciones = {
				title: "Condiciones legales",
				infoList: [],
			};
			oferta.condiciones.forEach((condicion) => {
				condiciones.infoList.push({ value: condicion.titulo });
			});
			features.push(condiciones);
		}

		return features;
	};

	static findBanners = (carline, salesgroup, tipo, useSalesgroupOfertas) => {
		const banners = [];
		if (carline.salesgroups) {
			carline.salesgroups.forEach((s) => {
				if (s.codigo === salesgroup.codigo) {
					// es el salesgroup correcto
					if (s.ofertas && useSalesgroupOfertas) {
						// sumar ofertas del salesgroup
						s.ofertas.forEach((oferta) => {
							if (oferta.isBanner) banners.push(oferta);
						});
					}
					if (s.trimlevels) {
						s.trimlevels.forEach((trimlevel) => {
							// sumar ofertas de cada trimlevel del salesgroup
							if (
								trimlevel.ofertas &&
								trimlevel.ofertas !== null &&
								trimlevel.ofertas.length > 0
							) {
								trimlevel.ofertas.forEach((oferta) => {
									if (oferta.isBanner) {
										if (!tipo || (tipo && oferta.tipo === tipo)) {
											banners.push({
												...oferta,
												trimlevel: {
													titulo: trimlevel.titulo,
												},
											});
										}
									}
								});
							}
						});
					}
				}
			});
		}
		return banners;
	};

	static buildURL = (carline, salesgroup, page) => {
		if (process.env.GATSBY_MODE === "development") {
			return `/${page}/${carline.codigo}/${salesgroup.codigo}`;
		} else {
			if (page === "renting-empresa") {
				const url = `https://www.audi.es/es/web/es/compra/audi-empresa/renting-${salesgroup.nombre}.html`;
				return url;
			} else {
				const nuevo = salesgroup.nuevo ? "nuevo-" : "";
				const url = `https://www.audi.es/es/web/es/modelos/${carline.nombre}/${nuevo}${salesgroup.nombre}/promociones/${page}.html`;
				return url;
			}
		}
	};

	static formatPrecio = (precio, dejarDecimalesEnCero = true) => {
		if (precio) {
			const roundedPrecio = precio.toFixed(2);
			let stringPrecio = roundedPrecio.toString();
			let stringPrecioParts = stringPrecio.split(".");
			let entero = stringPrecioParts[0];
			let decimal = stringPrecioParts[1];
			if (entero.length > 3) {
				const segundo = entero.substr(-3, 3);
				const primero = entero.substr(0, entero.length - 3);
				entero = `${primero}.${segundo}`;
			}
			if (!dejarDecimalesEnCero && decimal === "00") {
				stringPrecio = entero;
			} else {
				stringPrecio = `${entero},${decimal}`;
			}
			return stringPrecio;
		} else return "";
		// if (precio) {
		// 	precio += "";
		// 	let x = precio.split(".");
		// 	let x1 = x[0];
		// 	let x2 = x.length > 1 ? "," + x[1] : "";
		// 	let rgx = /(\d+)(\d{3})/;
		// 	while (rgx.test(x1)) {
		// 		x1 = x1.replace(rgx, "$1" + "." + "$2");
		// 	}
		// 	return x1 + x2;
		// } else return "";
	};

	static formatPrecio_OLD2 = (precio) => {
		// console.log("formatting", precio);
		if (precio) {
			const formatted = precio.toLocaleString("es-ES", {
				minimumIntegerDigits: 1,
				minimumFractionDigits: 21,
				maximumFractionDigits: 2,
				minimumSignificantDigits: 1,
				maximumSignificantDigits: 10,
			});
			// console.log("formatted to", formatted);
			return formatted;
		} else {
			// console.log("no precio");
			return "";
		}
	};
}

export default API;
