const actionTypes = {
	// APP REDIRECT

	APP_REDIRECT: "APP_REDIRECT",
	APP_REDIRECT_RESET: "APP_REDIRECT_RESET",

	// CARLINES

	CARLINES_LOAD_INIT: "CARLINES_LOAD_INIT",
	CARLINES_LOAD_SUCCESS: "CARLINES_LOAD_SUCCESS",
	CARLINES_LOAD_ERROR: "CARLINES_LOAD_ERROR",

	// CARLINE

	CARLINE_LOAD_INIT: "CARLINE_LOAD_INIT",
	CARLINE_LOAD_SUCCESS: "CARLINE_LOAD_SUCCESS",
	CARLINE_LOAD_ERROR: "CARLINE_LOAD_ERROR",
	CARLINE_SET: "CARLINE_SET",

	// SALESGROUP

	SALESGROUP_SET: "SALESGROUP_SET",

	// ESCALERA

	ESCALERA_LOAD_INIT: "ESCALERA_LOAD_INIT",
	ESCALERA_LOAD_SUCCESS: "ESCALERA_LOAD_SUCCESS",
	ESCALERA_LOAD_ERROR: "ESCALERA_LOAD_ERROR",

	// OFERTAS

	OFERTAS_LOAD_INIT: "OFERTAS_LOAD_INIT",
	OFERTAS_LOAD_SUCCESS: "OFERTAS_LOAD_SUCCESS",
	OFERTAS_LOAD_ERROR: "OFERTAS_LOAD_ERROR",
};

export default actionTypes;
