import actionTypes from "./actionTypes";
import { takeLatest, all } from "redux-saga/effects";
import * as carlinesSagas from "./carlines/sagas";

function* rootSaga() {
	yield all([
		takeLatest(actionTypes.CARLINES_LOAD_INIT, carlinesSagas.processCarlinesLoadInit),
		takeLatest(actionTypes.CARLINE_LOAD_INIT, carlinesSagas.processCarlineLoadInit),
		takeLatest(actionTypes.ESCALERA_LOAD_INIT, carlinesSagas.processEscaleraLoadInit),
		takeLatest(actionTypes.OFERTAS_LOAD_INIT, carlinesSagas.processOfertasLoadInit),
	]);
}

export default rootSaga;
