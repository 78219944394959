import actionTypes from "../actionTypes";

const initialState = {
	carlines: [],
	carlinesLoading: false,
	carline: null,
	carlineLoading: false,
	salesgroup: null,
	escalera: null,
	escaleraLoading: false,
	ofertas: [],
	ofertasLoading: false,
};

const carlinesReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		// carlines

		case actionTypes.CARLINES_LOAD_INIT:
			return {
				...state,
				carlinesLoading: true,
			};

		case actionTypes.CARLINES_LOAD_SUCCESS:
			return {
				...state,
				carlines: payload,
				carlinesLoading: false,
				// una vez que se cargan las carlines, se carga el carline y el salesgroup por defecto
				...(() => {
					let c = initialState.carline;
					let s = initialState.salesgroup;
					payload.forEach((carline) => {
						if (!c) {
							c = carline;
							if (carline.salesgroups) {
								carline.salesgroups.forEach((salesgroup) => {
									if (!s) {
										s = salesgroup;
									}
								});
							}
						}
					});
					return {
						carline: c,
						salesgroup: s,
					};
				})(),
			};

		case actionTypes.CARLINES_LOAD_ERROR:
			return {
				...state,
				carlines: initialState.carlines,
				carlinesLoading: false,
			};

		// carline

		case actionTypes.CARLINE_LOAD_INIT:
			return {
				...state,
				carlineLoading: true,
			};

		case actionTypes.CARLINE_LOAD_SUCCESS:
			return {
				...state,
				carline: payload,
				carlineLoading: false,
			};

		case actionTypes.CARLINE_LOAD_ERROR:
			return {
				...state,
				carline: initialState.carline,
				carlineLoading: false,
			};

		case actionTypes.CARLINE_SET:
			return {
				...state,
				carline: payload,
				carlineLoading: false,
				salesgroup: (() => {
					let s = initialState.salesgroup;
					if (payload.salesgroups) {
						payload.salesgroups.forEach((salesgroup) => {
							if (!s) {
								s = salesgroup;
							}
						});
					}
					return s;
				})(),
				escalera: initialState.escalera,
				escaleraLoading: false,
			};

		// salesgroup

		case actionTypes.SALESGROUP_SET:
			return {
				...state,
				salesgroup: payload,
			};

		// escalera

		case actionTypes.ESCALERA_LOAD_INIT:
			return {
				...state,
				escaleraLoading: true,
			};

		case actionTypes.ESCALERA_LOAD_SUCCESS:
			return {
				...state,
				escalera: payload,
				escaleraLoading: false,
			};

		case actionTypes.ESCALERA_LOAD_ERROR:
			return {
				...state,
				escalera: initialState.escalera,
				escaleraLoading: false,
			};

		// ofertas

		case actionTypes.OFERTAS_LOAD_INIT:
			return {
				...state,
				ofertasLoading: true,
			};

		case actionTypes.OFERTAS_LOAD_SUCCESS:
			return {
				...state,
				ofertas: payload,
				ofertasLoading: false,
			};

		case actionTypes.OFERTAS_LOAD_ERROR:
			return {
				...state,
				ofertas: initialState.ofertas,
				ofertasLoading: false,
			};

		default:
			return state;
	}
};

export default carlinesReducer;
