import actionTypes from "../actionTypes";
import { put, call } from "redux-saga/effects";
import API from "../../classes/api";

export function* processCarlinesLoadInit(action) {
	try {
		const carlines = yield call(API.getCarlines, action.payload);
		yield put({
			type: actionTypes.CARLINES_LOAD_SUCCESS,
			payload: carlines,
		});
	} catch (error) {
		yield put({
			type: actionTypes.CARLINES_LOAD_ERROR,
		});
	}
}

export function* processCarlineLoadInit(action) {
	try {
		const carline = yield call(API.getCarline, action.payload);
		yield put({
			type: actionTypes.CARLINE_LOAD_SUCCESS,
			payload: carline,
		});
	} catch (error) {
		yield put({
			type: actionTypes.CARLINE_LOAD_ERROR,
		});
	}
}

export function* processEscaleraLoadInit(action) {
	try {
		const escalera = yield call(API.getEscalera, action.payload);
		yield put({
			type: actionTypes.ESCALERA_LOAD_SUCCESS,
			payload: escalera,
		});
	} catch (error) {
		yield put({
			type: actionTypes.ESCALERA_LOAD_ERROR,
		});
	}
}

export function* processOfertasLoadInit(action) {
	try {
		const ofertas = yield call(API.getOfertas, action.payload);
		yield put({
			type: actionTypes.OFERTAS_LOAD_SUCCESS,
			payload: ofertas,
		});
	} catch (error) {
		yield put({
			type: actionTypes.OFERTAS_LOAD_ERROR,
		});
	}
}
